<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header>
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >DANH SÁCH THÔNG BÁO</b-badge>
      <b-button
        variant="primary"
        size="sm"
        @click="handleMarkAllRead"
      >
        Đánh dấu tất cả đã đọc
      </b-button>
    </b-card-header>
    <b-table
      ref="table-notifications"
      class="position-relative px-2"
      responsive
      primary-key="id"
      show-empty
      bordered
      small
      empty-text="Không có kết quả nào"
      :items="notifications"
      :fields="tableColumnsNotifications"
    >
      <template #cell(product_title)="data">
        <div class="py-50 minw-250">
          <router-link
            :class="data.item.status === 'unread' ? 'text-primary' : 'text-secondary'"
            :to="'/product-' + (data.item.meta.product_type === 'ban' ? 'sell/' : 'hired/') + data.item.product_id + '/detail'"
          >
            <strong>#{{ data.item.product_id }}</strong> - {{ data.item.meta.product_title }}
          </router-link>
        </div>
      </template>

      <template #cell(customer_name)="data">
        <router-link
          class="w-50"
          :class="data.item.status === 'unread' ? 'text-primary' : 'text-secondary'"
          :to="'/customer-' + (data.item.meta.customer_type === 'van-phong' ? 'office/' : 'corner/') + data.item.client_id + '/detail'"
        >
          <strong>#{{ data.item.client_id }}</strong> <br> {{ data.item.meta.client_name }}
        </router-link>
      </template>

      <template #cell(time)="data">
        <div class="text-right">
          {{ formatDate(data.item.send_at, true).split(' ').pop() }} <br>
          {{ formatDate(data.item.send_at) }}
        </div>
      </template>

      <template #cell(actions)="data">
        <div
          v-if="data.item.status === 'unread'"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            size="sm"
            class="p-50"
            @click="handleMarkRead(data.item)"
          >
            <feather-icon
              icon="CheckIcon"
            />
          </b-button>
        </div>
      </template>
    </b-table>

    <div class="mb-3">
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
        >
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>mỗi trang</label>
        </b-col>

        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, VBTooltip, BCardHeader, BBadge, BTable, BButton, BRow, BCol, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import userStoreModule from '@/views/settings/users/userStoreModule'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import useNotification from './useNotification'

export default {
  components: {
    BCard,
    BCardHeader,
    BBadge,
    BTable,
    BButton,
    BRow,
    BCol,
    BPagination,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created() {
    this.fetchNotifications()
  },
  methods: {
    handleMarkRead(noti) {
      this.$call(this.markRead(noti.id), true)
    },
    handleMarkAllRead() {
      this.$call(this.markAllRead(), true)
    },
  },
  setup() {
    const NOTIFICATION_STORE_MODULE_NAME = 'notification'
    if (!store.hasModule(NOTIFICATION_STORE_MODULE_NAME)) store.registerModule(NOTIFICATION_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(NOTIFICATION_STORE_MODULE_NAME)) store.unregisterModule(NOTIFICATION_STORE_MODULE_NAME)
    })

    return {
      ...useNotification(),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.card-body .card-body {
  padding: .5rem;
}
</style>
