import { ref, watch } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import http from '@/global/http'

export default function useNotificaiton() {
  const notifications = ref([])

  const perPage = ref(50)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)
  const total = ref(0)

  const tableColumnsNotifications = [
    {
      label: 'Sản phẩm',
      key: 'product_title',
    },
    {
      label: 'Khách hàng',
      key: 'customer_name',
    },
    {
      label: 'Thời gian',
      key: 'time',
    },
    {
      label: '',
      key: 'actions',
    },
  ]

  const fetchNotifications = cb => axiosIns.get(`/api.notification/notification${http.buildQuery({
    perpage: perPage.value,
    page: currentPage.value - 1,
  }) || ''}`).then(res => {
    notifications.value = res.data.data
    total.value = res.data.total
    return cb && cb(res.data.data)
  })

  const markRead = (id, cb) => axiosIns.put(`/api.notification/notification/${id}/read`).then(res => {
    const found = notifications.value.find(n => n.id === id)
    found.status = 'read'
    return cb && cb(res.data.data)
  })

  const markAllRead = (id, cb) => axiosIns.put('/api.notification/notification/read-all').then(res => {
    notifications.value.forEach(n => {
      Object.assign(n, { status: 'read' })
    })
    return cb && cb(res.data.data)
  })

  watch([currentPage, perPage], () => {
    fetchNotifications()
  })

  return {
    perPage,
    perPageOptions,
    currentPage,
    total,
    notifications,
    tableColumnsNotifications,
    fetchNotifications,
    markRead,
    markAllRead,
  }
}
